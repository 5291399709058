<template>
<div class="row">
    <div class="col-md-12">
        <span class="h4 titulosg mt-0"><strong>Asignación de Permisos Grupal</strong></span> <br>
        <span class="text-secondary">Asgine los permisos a los usuario según la categoría </span>

        <div class="alert alert-warning mt-3">
            <i class="fas fa-exclamation-circle"></i> Los permisos no seleccionados son igualmente sincronizados, esto significa que si no se seleccionan seran eliminados de la categoría seleccionada
        </div>

        <div class="mt-4">

        <form  @submit.prevent="agregaroquitarpermiso">

                <div class="form-group mb-4">
                    <label  for="categorias" class="form-label">Seleccione el grupo al cual desea aplicar para los permisos </label>
                    <select id="categorias" v-model="categoriaseleccionada" class="form-select form-control-lg" required >
                        <option selected value="">-</option>
                        <option  value="todos">Todos</option>
                        <option v-for="item in categorias" :key="item.idpersonascategoria" :value="item.idpersonascategoria">{{item.nombrecategoria}}</option>
                    </select>
                </div>

                <div class="p-2">
                    Seleccione los permisos que desea asignar al grupo
                </div>
                
                    <div class="mb-3" v-for="permiso in permisos.filter(permisos => permisos.permisos_idpermisos === null)" :key="permiso.idpermisos" >
                        <div class="card">
                                <div class="p-3">
                                    <input type="checkbox" class="form-check-input me-3" name="permisosguardar[]" :id="permiso.idpermisos" :value="permiso.idpermisos" v-model="permisosseleccionados">

                                        <a :href="'#p'+permiso.idpermisos" role="button"  data-bs-toggle="collapse" aria-expanded="false" aria-controls="collapseExample">                             
                                            <span v-html="permiso.icono" class="me-2"></span>  <strong class="titulosg">{{ permiso.nombrepermiso }}</strong>   <i class="fas fa-sort-down me-5"></i> 
                                        </a>
                                        <br>
                                        <span class="text-black-50"> {{permiso.descripcion}} </span>
                                </div>
        
                                <div class="collapse" :id="'p'+permiso.idpermisos" >
                                    <div class="list-group list-group-flush">
                                        <label class="list-group-item list-group-item-action" v-for="(sub, index) in subpermisos.filter(permisos => permisos.permisos_idpermisos === permiso.idpermisos)" :key="sub.idpermisos">                                              
                                        <input type="checkbox" class="form-check-input me-3" name="permisosguardar[]" :id="sub.idpermisos" :value="sub.idpermisos" v-model="permisosseleccionados">
                                        <span v-html="sub.icono" class=" text-dark me-2"></span> {{ sub.nombrepermiso }}  
                                                    <span class="text-black-50 ms-3">{{ sub.descripcion }}</span>   
                                        </label>                                             
                                    </div>
                                </div>
        
                        </div>
                    </div>

                <div class="alert alert-warning">
                    Permisos Asignar <span class="badge bg-primary">{{permisosseleccionados.length}}</span> de <span class="badge bg-secondary"> {{permisos.length}} </span>
                </div>

                <button type="submit" class="btn  btn-primary" :disabled="boton">

                    <div class="spinner-border spinner-border-sm" role="status" v-if="boton">
                        <span class="visually-hidden">Loading...</span>
                      </div>

                    <i class="fas fa-save"></i>  Asignar Permisos
                </button>

        </form>

        </div>

</div>
</div>

</template>

<script>

import {ref, onMounted} from 'vue'
import axios from 'axios'

export default {
    setup(props) {

        const permisos = ref([]) 
        const subpermisos = ref([]) 
        const permisosseleccionados = ref([]) 
        const categorias = ref(null) 
        const categoriaseleccionada = ref(null)
        
        const boton = ref(false)

        const agregaroquitarpermiso = () => {

                boton.value = true
                axios.post('/api/agregarpermisosgrupo', { permisos: permisosseleccionados.value, categoria: categoriaseleccionada.value }).then(response => { 

                    console.log(response.data)

                    if(response.status==200)
                    {
                        swal({
                                    icon: 'success',
                                    title:'Permisos Asigandos Correctamente!',
                                    text: '[ '+response.data+' ] usuarios consultados, Por favor actualice el sitio para visualizar los nuevos permisos asociados a su cuenta',
                                    })

                        boton.value = false
                    }
                }).catch(error => { 
                    console.log(error)
                    })

        }

        const Permisos = () => {
            axios.get('/api/permisos').then(response => { 
                    permisos.value = response.data  
                    subpermisos.value = response.data
                })
        }

        const Categorias = () => {

        axios.get('/api/tiposdepersonas').then(response => { 
            categorias.value = response.data  
             })
        }

        onMounted(() => {
                            Permisos()        
                            Categorias()
                        })

        return {
            boton,
            permisos,
            subpermisos,
            permisosseleccionados,
            categorias,
            categoriaseleccionada,

            //Funciones
            agregaroquitarpermiso,

        }
    }
 }
</script>

 